<template>
  <div class="woo">
    <div class="await">
      <a-spin size="large" class="aspin" />
    </div>
  </div>
</template>
<script>
import { setInviteInfo } from '@/api/channel'

export default {
  data() {
    return {
    }
  },
  async mounted() {
    const inviteCode = this.$route.query.inviteCode
    if (inviteCode) {
      await setInviteInfo({ inviteCode: inviteCode })
      this.callNewPage({
        path: '/'
      })
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.await {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  z-index: 1;
  display: flex;
  .aspin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
