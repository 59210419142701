import { apiAxios } from '../utils/request.js'

/** *
 * 设置邀请信息
 * @param data
 * @param Istoast
 * @param Send
 * @returns {Promise | Promise<unknown>}
 */
export const setInviteInfo = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/channel/setInviteInfo', data, Istoast, Send)
}
